import { render, staticRenderFns } from "./MarketDetail.vue?vue&type=template&id=c5a0e4ae&scoped=true"
import script from "./MarketDetail.vue?vue&type=script&lang=js"
export * from "./MarketDetail.vue?vue&type=script&lang=js"
import style0 from "./MarketDetail.vue?vue&type=style&index=0&id=c5a0e4ae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5a0e4ae",
  null
  
)

export default component.exports