<template>
  <Wrapper>
    <DetailBanner title=""></DetailBanner>
    <div class="market-detail">
      <div class="w">
        <div class="hd">
          <div class="price-product">
            <div class="price">今日参考价格： {{priceObj.price}}(元/公斤)</div>
            <div class="product">{{priceObj.mname}}-{{priceObj.mspec}}</div>
          </div>
          <ul>
            <li>
              <div class="image">
                <img src="@/assets/imgs/compare.png" />
              </div>
              <div class="text">
                <div class="small">
                  <p>相比上周</p>
                  <img src="@/assets/imgs/compare1.png" v-if="priceObj.week_range>=0" />
                  <img src="@/assets/imgs/compare2.png" v-else />
                </div>
                <div class="big">{{priceObj.week_range}}%</div>
              </div>
            </li>
            <li>
              <div class="image">
                <img src="@/assets/imgs/compare.png" />
              </div>
              <div class="text">
                <div class="small">
                  <p>相比上月</p>
                  <img src="@/assets/imgs/compare1.png" v-if="priceObj.month_range>=0" />
                  <img src="@/assets/imgs/compare2.png" v-else />
                </div>
                <div class="big">{{priceObj.month_range}}%</div>
              </div>
            </li>
            <li>
              <div class="image">
                <img src="@/assets/imgs/compare.png" />
              </div>
              <div class="text">
                <div class="small">
                  <p>相比上季</p>
                  <img src="@/assets/imgs/compare1.png" v-if="priceObj.quarter_range>=0" />
                  <img src="@/assets/imgs/compare2.png" v-else />
                </div>
                <div class="big">{{priceObj.quarter_range}}%</div>
              </div>
            </li>
            <li>
              <div class="image">
                <img src="@/assets/imgs/compare.png" />
              </div>
              <div class="text">
                <div class="small">
                  <p>相比上年</p>
                  <img src="@/assets/imgs/compare1.png" v-if="priceObj.yearr_range>=0" />
                  <img src="@/assets/imgs/compare2.png" v-else />
                </div>
                <div class="big">{{priceObj.yearr_range}}%</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="bd">
          <!-- 暂时先注释 -->
          <!-- <div class="search-area">
            <el-form inline label-width="80px">
              <el-form-item label="品种">
                <el-input v-model="aaa"></el-input>
              </el-form-item>
              <el-form-item label="时间">
                <el-date-picker v-model="bbb" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-form>
            <div>
              <el-button icon="el-icon-refresh-left">重置</el-button>
              <el-button type="primary" icon="el-icon-search">搜索</el-button>
            </div>
          </div> -->
          <div class="class-list">
            <classify cname="市场:" :actId="marketValue" :forArr="markets" @selelctId="selelctId1" />
            <classify cname="产地:" :actId="addrValue" :forArr="addrs" @selelctId="selelctId2" />
            <classify cname="规格:" act_class="act_2" :actId="specValue" :forArr="specs"
              @selelctId="selelctId3" />
            <!-- 暂时先注释 -->
            <!-- <div class="time-range">
              <div class="label">时间区间:</div>
              <div class="tabs">
                <div class="tab left active">全部</div>
                <div class="tab right">近四年</div>
              </div>
            </div> -->
          </div>
          <div class="sheets">
            <div class="con2-box">
              <myChart v-if="optionData1.yAxis" :key="myChartInit1" red-id="mymchart1"
                :option="optionData1" ref="mchart1" />
            </div>
            <!-- <div class="con2-box">
              <myChart v-if="optionData2.yAxis" :key="myChartInit2" red-id="mymchart2"
                :option="optionData2" ref="mchart2" />
            </div> -->
          </div>
          <div class="table">
            <div class="table-hd">
              <h3>历史价格</h3>
              <a>查看更多</a>
            </div>
            <div class="table-bd">
              <table>
                <thead>
                  <tr>
                    <th v-for="item in tablelist[0]" :key="item">{{item}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(father, index) in tablelist.slice(1)" :key="index">
                    <td v-for="(son, sonIndex) in father" :key="sonIndex">{{son}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
</template>

<script>
import DetailBanner from '../sections/DetailBanner.vue'
import Wrapper from '../../components/Wrapper.vue'
import classify from './serviceComponents/classify.vue'
import myChart from '@/components/myChart/index'

export default {
  mixins: [],
  components: {
    Wrapper,
    DetailBanner,
    classify,
    myChart
  },
  data() {
    return {
      form: {
        id: '',
        market: '',
        maddr: '',
        mspec: ''
      },
      priceObj: {},
      tablelist: [],
      aaa: '',
      bbb: '',
      markets: [
        { key: '亳州', name: '亳州市场', id: '1' },
        { key: '安国', name: '安国市场', id: '2' },
        { key: '成都', name: '成都市场', id: '3' },
        { key: '玉林', name: '玉林市场', id: '4' }
      ],
      marketValue: '',
      addrs: [],
      addrValue: '',
      specs: [],
      specValue: '',
      optionData1: {
        title: [
          {
            top: '20',
            left: '20',
            text: '历史价格图表'
          }
        ],
        grid: {
          top: '70',
          bottom: '40'
        },
        xAxis: {
          //下面的x坐标
          type: 'category',
          data: []
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          //每个x左边的y值
          {
            data: [],
            type: 'line'
          }
        ]
      },
      optionData2: {
        title: [
          {
            top: '20',
            left: '20',
            text: '历史价格图表2'
          }
        ],
        grid: {
          top: '70',
          bottom: '40'
        },
        xAxis: {
          //下面的x坐标
          type: 'category',
          data: []
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          //每个x左边的y值
          {
            data: [],
            type: 'line'
          }
        ]
      },
      myChartInit1: '111',
      myChartInit2: '111'
    }
  },
  created() {
    this.form.id = this.$route.query.id
    this.init()
  },
  methods: {
    async init() {
      const res = await this.getDetail()
      this.priceObj = res.data.priceObj
      // 获取产地和规格
      await this.getTabs(this.priceObj.mname)
      // 选中市场
      const marketIndex = this.markets.findIndex((item) => {
        return item.name === this.priceObj.market
      })
      if (marketIndex >= 0) {
        this.marketValue = this.markets[marketIndex].id
      }

      // 选中产地
      const addrIndex = this.addrs.findIndex((item) => {
        return item.name === this.priceObj.maddr
      })
      if (addrIndex >= 0) {
        this.addrValue = this.addrs[addrIndex].id
      }
      // 选中规格
      const specIndex = this.specs.findIndex((item) => {
        return item.name === this.priceObj.mspec
      })
      if (specIndex >= 0) {
        this.specValue = this.specs[specIndex].id
      }
      // 渲染图表
      this.initChart(res)
      // 渲染表格
      this.tablelist = res.data.tablelist
    },
    async getDetail() {
      const res = await this.$axios.getHisPrice(this.form)
      return res
    },
    async getTabs(name) {
      const res = await this.$axios.getAddrAndSpec({ name })
      this.addrs = res.data.address.map((item) => {
        return {
          key: item,
          name: item,
          id: item
        }
      })
      this.specs = res.data.spec.map((item) => {
        return {
          key: item,
          name: item,
          id: item
        }
      })
    },
    async initChart(res) {
      // 渲染表格
      this.optionData1.xAxis.data = res.data.jsonArrX
      this.optionData1.series[0].data = res.data.jsonArrY
      this.$refs.mchart1.setOption(this.optionData1)
    },
    async selelctId1(item) {
      const index = this.markets.findIndex((son) => {
        return son.name === item.name
      })
      if (index >= 0) {
        this.marketValue = this.markets[index].id
      }
      this.form.market = item.name
      const res = await this.getDetail()
      this.initChart({
        data: {
          jsonArrX: [],
          jsonArrY: []
        }
      })
      this.tablelist = []
      setTimeout(() => {
        // 渲染图表
        this.initChart(res)
        // 渲染表格
        this.tablelist = res.data.tablelist
      }, 1000)
    },
    async selelctId2(item) {
      const index = this.addrs.findIndex((son) => {
        return son.name === item.name
      })
      if (index >= 0) {
        this.addrValue = this.addrs[index].id
      }
      this.form.maddr = item.name
      const res = await this.getDetail()
      this.initChart({
        data: {
          jsonArrX: [],
          jsonArrY: []
        }
      })
      this.tablelist = []
      setTimeout(() => {
        // 渲染图表
        this.initChart(res)
        // 渲染表格
        this.tablelist = res.data.tablelist
      }, 1000)
    },
    async selelctId3(item) {
      const index = this.specs.findIndex((son) => {
        return son.name === item.name
      })
      if (index >= 0) {
        this.specValue = this.specs[index].id
      }
      this.form.mspec = item.name
      const res = await this.getDetail()
      this.initChart({
        data: {
          jsonArrX: [],
          jsonArrY: []
        }
      })
      this.tablelist = []
      setTimeout(() => {
        // 渲染图表
        this.initChart(res)
        // 渲染表格
        this.tablelist = res.data.tablelist
      }, 1000)
    },
    goback() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='scss' scoped>
.market-detail {
  background-color: #f8f8f8;
}
.hd {
  margin-top: -100px;
  margin-bottom: 25px;
  background-color: #fff;
  position: relative;
  z-index: 20;
  .price-product {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: #181f2d;
    font-weight: 600;
  }
  ul {
    display: flex;
    margin-top: 36px;
  }
  li {
    display: flex;
    align-items: center;
    padding-left: 50px;
    &:last-child {
      .text {
        border-right: 0;
      }
    }

    .text {
      border-right: 1px solid #dbdfe9;
      padding-right: 50px;
    }
    .small {
      display: flex;
      align-items: center;
    }
    p {
      font-size: 12px;
      color: #536387;
      margin-right: 8px;
    }
    .big {
      font-size: 28px;
      color: #0d162a;
      margin-top: 6px;
    }
  }
  padding: 24px;
}
.bd {
  padding: 34px 24px;
  background-color: #fff;
  .search-area {
    display: flex;
    justify-content: space-between;
  }
  .time-range {
    font-size: 14px;
    color: #181f2d;
    display: flex;
    align-items: center;
    margin-top: 18px;
    margin-bottom: 24px;
  }
  .tabs {
    display: flex;
    color: #000000;
    font-size: 14px;
    border: 4px;
    margin-left: 20px;
    .tab {
      padding: 5px 16px;
      cursor: pointer;

      &.left {
        border: 1px solid #ccc;
        border-radius: 4px 0px 0px 4px;
      }
      &.right {
        border: 1px solid #ccc;
        border-radius: 0px 4px 4px 0px;
      }
      &.active {
        border-color: #315ccf;
        color: #315ccf;
        font-weight: 700;
      }
    }
  }
}
.con2-box {
  width: 1200px;
  height: 376px;
  margin-bottom: 32px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.sheets {
  display: flex;
  justify-content: space-between;
}
.table {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 20px 24px;
  .table-hd {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    margin-bottom: 26px;
    a {
      font-size: 14px;
      color: #224cda;
    }
  }
  h3 {
    font-size: 18px;
    color: #181f2d;
    font-weight: 700;
  }
}
.table-bd {
  height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f1f1f1;
  }

  /* 设置滚动条滑块的样式 */
  &::-webkit-scrollbar-thumb {
    background-color: #dbdfe9;
    border-radius: 2px;
  }

  /* 设置滚动条滑块悬停时的样式 */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /* 设置滚动条轨道的样式 */
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
}
table {
  width: 100%;
  tr {
    border-bottom: 1px solid #dcdfe8;
  }
  th {
    background-color: #f6f8fc;
  }
  td {
    font-size: 14px;
    font-weight: 700;
    color: #536387;
  }
  td:first-child {
    background-color: #f6f8fc;
    color: #181f2d;
  }
  th:first-child,
  td:first-child {
    border-right: 1px solid #dbdfe9;
  }
  th,
  td {
    padding: 20px 10px;
    text-align: center;
    white-space: nowrap;
  }
}
</style>
